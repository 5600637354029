<template>
  <div v-if="job.id" class="jobContainer">
    <div class="header">
      <div class="location flex">
        <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="M24 4c-7.73 0-14 6.27-14 14 0 10.5 14 26 14 26s14-15.5 14-26c0-7.73-6.27-14-14-14zm0 19c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" fill="#51a7dd" class="fill-000000" /><path d="M0 0h48v48H0z" fill="none" /></svg>
        {{ job.locationCity }} - {{ job.locationName }}
      </div>
    </div>
    <div class="title flex">
      <h3>{{ job.title }} (m/w/d)</h3>
      <!--      <a href=""><button class="button primary headerApply">-->
      <!--        Jetzt Bewerben-->
      <!--      </button></a>-->
    </div>
    <img :src="config.projectURL + 'assets/images/jobs/' + job.titleimage + '.jpg'" class="titleImage">

    <div class="grid-5 benefitWrapper">
      <div class="col-sp-5 col-sd-1 row-sp-5 mrg">
        <div class="benefit flex">
          <h6>📅</h6>
          <p> {{ formatDate(job.startdate) }} </p>
        </div>
      </div>
      <div class="col-sp-5 col-sd-1 row-sp-5 mrg">
        <div class="benefit flex">
          <h6>📑</h6>
          <p> {{ job.worktype }} </p>
        </div>
      </div>
      <div v-for="benefit in benefits" :key="benefit.id" class="col-sp-5 col-sd-1 row-sp-5 mrg ">
        <div class="benefit flex">
          <h6> {{ benefit.img }} </h6>
          <p> {{ benefit.title }} </p>
        </div>
      </div>
    </div>

    <div class="description">
      <h4>Dein neuer Arbeitsplatz</h4>
      <p v-html="nl2br(job.description)" />
    </div>
    <div class="pad-16" />
    <div class="offer">
      <h4>Unser Angebot</h4>
      <p v-html="nl2br(job.offer)" />
    </div>
    <div class="pad-16" />
    <div class="profile">
      <h4>Das wünschen wir uns von dir</h4>
      <p v-html="nl2br(job.profile)" />
    </div>
    <funnel :job-id="job.id" />
  </div>
  <div v-else class="info">
    Jetzt Traumjob finden
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import Funnel from "@/components/apply/funnel";
import {nl2br} from "@/modules/main";


export default {
  name: "JobDetailsView",
  components: {Funnel},
  props: {
    job: {
      type: Object,
      required: false
    }
  },
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      benefits: [],
    }
  },
  mounted() {
    this.config.loading = false
    this.getBenefits()
  },
  methods: {
    getBenefits(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', this.job.benefits)
      this.axios.post(this.config.projectURL + 'apply/getBenefits', fD)
          .then(response => {
            if(response.status === 250){
              _this.benefits = response.data
            }
          })
          .catch(e => {
            console.error(e)
          })
    },
    formatDate(date){
      const jsDate = date.split('-')
      return jsDate[2]+'.'+jsDate[1]+'.'+jsDate[0];
    },
    nl2br(str){
      return nl2br(str)
    }
  }
}
</script>

<style scoped>
.jobContainer{

}
.header{
  width: 100%;
}

.flex {
  display: flex;
}

.header > div{
  font-weight: lighter;
  font-size: 0.8em;
}

.title{
  align-items: center;
  justify-content: space-between;
  margin: 6px 0 4px;
}

.title h3{
  font-size: 1em;
  font-weight: bold;
}
.titleImage{
  margin: 12px 0;
  width: 100%;
}
.info{
  margin: auto;
  text-align: center;
}

.jobContainer {
  background-color: #FAFAFA;
  border-radius: 10px;
  margin: 10px;
  padding: 18px 40px;
  overflow: scroll;
}

.jobContainer .title .headerApply{
  margin: 8px 0;
  font-size: 16px !important;
}

.jobContainer .location{
  align-items: center;
  column-gap: 2px;
}

.jobContainer .location svg{
  width: 24px;;
}

.jobContainer .title{
  font-size: 1.4em;
  font-weight: 600;
}

.jobContainer img {
  border-radius: 20px;
}

.jobContainer h4{
  font-family: 'SueEllenFrancisco', sans-serif;
  color: var(--theme-pflegediakonie-primary);
  font-weight: normal;
  font-size: 34px;
  line-height: 1.1;
  padding-bottom: 16px;
}

.jobContainer p{
  line-height: 1.4;
}

.jobContainer .benefitWrapper{
  column-gap: 12px;
  grid-template-rows: none !important;
  margin: 0 0 40px;
  row-gap: 10px;
}

.jobContainer .benefit{
  column-gap: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-color: var(--theme-pflegediakonie-secondary);
  border-radius: 20px;
  padding: 8px;
  text-align: center;
  height: 100%;

}

.jobContainer .benefit h6{
  font-size: 1.9em;
}

.jobContainer .benefit p{
  font-size: 12px;
}

@media (max-width: 767px) {
  .mrg{
    margin: 8px 0;
  }

  .jobContainer{
    padding: 18px 16px;
  }

  .jobContainer h4{
    padding-bottom: 8px;
  }
}

</style>