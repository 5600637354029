<template>
  <main class="content-1400 page-content">
    <div class="grid-12">
      <div class="col-sp-12 col-tb-8 row-sp-12 pad-8">
        <router-link to="/bewerbungen" class="button lightgrey">
          Zurück zur Übersicht
        </router-link>
        <h1 class="mar-top-8">
          [#{{ userDetails.id }}] {{ userDetails.name }}
        </h1>
      </div>
      <div class="col-sp-12 col-sd-4 row-sp-12 pad-8 t-right pad-top-16">
        <button class="button large primary" @click="emailSection = true;">
          Bewerber*in schreiben
        </button>
      </div>
    </div>
    <div class="pad-8" />
    <div class="grid-12">
      <div class="col-sp-12 col-sd-8 row-sp-12 pad-8">
        <div v-if="feedback != ''" class="t-center pad-16">
          <span class="label green">{{ feedback }}</span>
        </div>
        <div v-if="emailSection" class="bgcolor-lightblue pad-16 br-10 grid-12 mar-bottom-16">
          <div class="col-sp-12 col-tb-10 row-sp-12 pad-4">
            <h5 class="color-primary mar-bottom-16 pad-4">
              E-Mail schreiben:
            </h5>
          </div>
          <div class="col-sp-12 col-tb-2 row-sp-12 t-right pad-4">
            <a @click="emailSection = false;" style="cursor: pointer;" class="color-primary">Abbrechen</a>
          </div>
          <div class="col-sp-12 row-sp-12 pad-4">
            <div class="field">
              <label>Vorlage auswählen:</label>
              <select v-model="template" @change="changeTemplate()" id="TemplateSelection">
                <option value="0" selected>
                  Keine Vorlage
                </option>
                <option value="1" selected>
                  Template: Einladung zum Bewerbungsgespräch
                </option>
              </select>
            </div>
            <div class="field mar-top-16">
              <label>Nachricht:</label>
              <textarea id="emailContent" rows="8" v-model="emailContent" />
            </div>
          </div>
          <div class="col-sp-12 row-sp-12 pad-4">
            <div class="t-center">
              <h6 class="color-primary mar-bottom-8">
                E-Mail Vorschau
              </h6>
            </div>
            <div class="bgcolor-white pad-16 br-10">
              <div v-html="nl2br(emailContent)" />
              <div v-html="nl2br(footer)" />
            </div>
          </div>
          <div class="col-sp-12 row-sp-12 pad-4 t-right pad-top-16">
            <button class="button primary" @click="sendMail()" v-if="emailContent != ''">
              Absenden
            </button>
          </div>
        </div>
        <div class="bgcolor-lightblue pad-16 br-10 grid-12">
          <div class="col-sp-12 col-sd-6 row-sp-12 mar-bottom-32">
            <h5 class="color-primary mar-bottom-16 pad-4">
              Kontaktdaten:
            </h5>
            <div class="grid-12">
              <div class="col-sp-10 row-sp-12 pad-4">
                <p>
                  <b>E-Mail-Adresse:</b><br>
                  {{ userDetails.email }}
                </p>
              </div>
              <div class="col-sp-2 row-sp-12 t-right pad-4 pad-right-12">
                <button class="primary button pad-4" @click="emailSection = true;">
                  <svg style="vertical-align: -8px;" width="24px" viewBox="0 0 64 64" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 64 64"><path d="m-22.5-213.2-1.9-1.9-17.6 17.6c-2.2 2.2-5.9 2.2-8.1 0L-67.7-215l-1.9 1.9 13.1 13.1-13.1 13.1 1.9 1.9 13.1-13.1 2.6 2.6c1.6 1.6 3.7 2.5 5.9 2.5s4.3-.9 5.9-2.5l2.6-2.6 13.1 13.1 1.9-1.9-13.1-13.1 13.2-13.2" transform="translate(78 232)" fill="#ffffff" class="fill-134563" /><path d="M-26.2-181.6h-39.5c-2.3 0-4.2-1.9-4.2-4.2V-214c0-2.3 1.9-4.2 4.2-4.2h39.5c2.3 0 4.2 1.9 4.2 4.2v28.2c0 2.3-1.9 4.2-4.2 4.2zm-39.6-33.9c-.8 0-1.4.6-1.4 1.4v28.2c0 .8.6 1.4 1.4 1.4h39.5c.8 0 1.4-.6 1.4-1.4v-28.2c0-.8-.6-1.4-1.4-1.4h-39.5z" transform="translate(78 232)" fill="#ffffff" class="fill-134563" /></svg>
                </button>
              </div>
              <div class="col-sp-12 row-sp-12 pad-4" />
              <div class="col-sp-10 row-sp-12 pad-4">
                <p>
                  <b>Telefonnummer:</b><br>
                  {{ userDetails.phone }}
                </p>
              </div>
              <div class="col-sp-2 row-sp-12 t-right pad-4 pad-right-12">
                <a :href="'tel:'+userDetails.phone" class="primary button pad-4">
                  <svg style="vertical-align: -8px;" width="24px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><g data-name="1"><path d="M348.73 450.06a198.63 198.63 0 0 1-46.4-5.85c-52.43-12.65-106.42-44.74-152-90.36s-77.71-99.62-90.36-152c-13.32-55.1-3.82-102.24 26.72-132.78l8.72-8.72a42.2 42.2 0 0 1 59.62 0l50.11 50.1a42.18 42.18 0 0 1 0 59.62l-29.6 29.59c14.19 24.9 33.49 49.82 56.3 72.63s47.75 42.12 72.64 56.31l29.59-29.6a42.15 42.15 0 0 1 59.62 0l50.1 50.1a42.16 42.16 0 0 1 0 59.61l-8.73 8.72c-21.53 21.57-51.33 32.63-86.33 32.63ZM125.22 78a12 12 0 0 0-8.59 3.56l-8.73 8.72c-22.87 22.87-29.55 60-18.81 104.49 11.37 47.13 40.64 96.1 82.41 137.86s90.73 71 137.87 82.41c44.5 10.74 81.61 4.06 104.48-18.81l8.72-8.72a12.16 12.16 0 0 0 0-17.19l-50.09-50.1a12.16 12.16 0 0 0-17.19 0l-37.51 37.51a15 15 0 0 1-17.5 2.72c-30.75-15.9-61.75-39.05-89.65-66.95s-51-58.88-66.94-89.63a15 15 0 0 1 2.71-17.5l37.52-37.51a12.16 12.16 0 0 0 0-17.19l-50.1-50.11a12.07 12.07 0 0 0-8.6-3.56Z" fill="#ffffff" class="fill-000000" /><path d="M364.75 269.73a15 15 0 0 1-15-15 99.37 99.37 0 0 0-99.25-99.26 15 15 0 0 1 0-30c71.27 0 129.25 58 129.25 129.26a15 15 0 0 1-15 15Z" fill="#ffffff" class="fill-000000" /><path d="M428.15 269.73a15 15 0 0 1-15-15c0-89.69-73-162.66-162.65-162.66a15 15 0 0 1 0-30c106.23 0 192.65 86.43 192.65 192.66a15 15 0 0 1-15 15Z" fill="#ffffff" class="fill-000000" /></g></svg>
                </a>
              </div>
            </div>
          </div>
          <div class="col-sp-12 col-sd-6 row-sp-12">
            <h5 class="color-primary mar-bottom-16 pad-4">
              Verlauf:
            </h5>
            <div class="pad-8">
              <p v-for="historyitem in historyitems" :key="historyitem.id">
                [{{ historyitem.timestamp }}] <b>{{ historyitem.statusText }}</b>
              </p>
              <p>[{{ userDetails.timestamp }}] <b>Bewerbung eingegangen</b></p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sp-12 col-sd-4 row-sp-12 pad-8">
        <div class="bgcolor-lightblue pad-16 br-10">
          <h5 class="color-primary f-normal pad-8 t-center">
            Bewerbungs Status
          </h5>
          <div id="Status">
            <div class="label blue" :class="[userDetails.status == 0 ? 'active' : '']" @click="updateStatus(0)">
              Neue Bewerbung
            </div>
            <div class="label blue" :class="[userDetails.status == 1 ? 'active' : '']" @click="updateStatus(1)">
              In Korrespondenz
            </div>
            <div class="label blue" :class="[userDetails.status == 2 ? 'active' : '']" @click="updateStatus(2)">
              Vorstellungsgespräch
            </div>
            <div class="label green" :class="[userDetails.status == 3 ? 'active' : '']" @click="updateStatus(3)">
              Eingestellt
            </div>
            <div class="label red" :class="[userDetails.status == 4 ? 'active' : '']" @click="updateStatus(4)">
              Abgelehnt
            </div>
            <div class="label yellow" :class="[userDetails.status == 5 ? 'active' : '']" @click="updateStatus(5)">
              Auf Warteliste
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid-12">
      <div class="col-sp-12 col-sd-4 row-sp-12">
        <h4 class="color-primary f-normal pad-8">
          Erreichbar zu folgenden Zeiten:
        </h4>
        <div class="select-wrapper grid-12">
          <div v-if="userDetails.time1 == 1" class="col-sp-12 row-sp-12 pad-8">
            <div class="bgcolor-secondary pad-16 br-10 grid-12 traitBox">
              <div class="col-sp-2 row-sp-12 t-left">
                <img src="assets/images/sun.png" class="width-40">
              </div>
              <div class="col-sp-8 row-sp-12 pad-4">
                <h5 class="color-grey f-normal mar-top-8" style="font-size: 1.2em;">
                  Jederzeit
                </h5>
              </div>
              <div class="col-sp-2 row-sp-12 t-right">
                <svg width="40px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 1a11 11 0 1 0 11 11A11 11 0 0 0 12 1Zm5.707 8.707-7 7a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 1.414-1.414L10 14.586l6.293-6.293a1 1 0 0 1 1.414 1.414Z" fill="#51a7dd" class="fill-232323" /></svg>
              </div>
            </div>
          </div>
          <div v-if="userDetails.time2 == 1" class="col-sp-12 row-sp-12 pad-8">
            <div class="bgcolor-secondary pad-16 br-10 grid-12 traitBox">
              <div class="col-sp-2 row-sp-12 t-left">
                <img src="assets/images/clock.png" class="width-40">
              </div>
              <div class="col-sp-8 row-sp-12 pad-4">
                <h5 class="color-grey f-normal mar-top-8" style="font-size: 1.2em;">
                  Vormittags von 8 - 12 Uhr
                </h5>
              </div>
              <div class="col-sp-2 row-sp-12 t-right">
                <svg width="40px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 1a11 11 0 1 0 11 11A11 11 0 0 0 12 1Zm5.707 8.707-7 7a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 1.414-1.414L10 14.586l6.293-6.293a1 1 0 0 1 1.414 1.414Z" fill="#51a7dd" class="fill-232323" /></svg>
              </div>
            </div>
          </div>
          <div v-if="userDetails.time3 == 1" class="col-sp-12 row-sp-12 pad-8">
            <div class="bgcolor-secondary pad-16 br-10 grid-12 traitBox">
              <div class="col-sp-2 row-sp-12 t-left">
                <img src="assets/images/clock-2.png" class="width-40">
              </div>
              <div class="col-sp-8 row-sp-12 pad-4">
                <h5 class="color-grey f-normal mar-top-8" style="font-size: 1.2em;">
                  Nachmittags von 12 - 16 Uhr.
                </h5>
              </div>
              <div class="col-sp-2 row-sp-12 t-right">
                <svg width="40px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 1a11 11 0 1 0 11 11A11 11 0 0 0 12 1Zm5.707 8.707-7 7a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 1.414-1.414L10 14.586l6.293-6.293a1 1 0 0 1 1.414 1.414Z" fill="#51a7dd" class="fill-232323" /></svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sp-12 col-sd-4 row-sp-12">
        <h4 class="color-primary f-normal pad-8">
          Eigenschaften:
        </h4>
        <div class="select-wrapper grid-12">
          <div class="col-sp-12 row-sp-12 pad-8">
            <div class="bgcolor-secondary pad-16 br-10 grid-12 traitBox" v-if="userDetails.treat1 == 1">
              <div class="col-sp-2 row-sp-12 t-left">
                <img src="assets/images/bild-28.png" class="width-40">
              </div>
              <div class="col-sp-8 row-sp-12 pad-4">
                <p class="color-grey f-normal" style="font-size: 1em;">
                  Ich habe noch nie in diesem Beruf gearbeitet, möchte es jetzt aber.
                </p>
              </div>
              <div class="col-sp-2 row-sp-12 t-right">
                <svg width="40px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 1a11 11 0 1 0 11 11A11 11 0 0 0 12 1Zm5.707 8.707-7 7a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 1.414-1.414L10 14.586l6.293-6.293a1 1 0 0 1 1.414 1.414Z" fill="#51a7dd" class="fill-232323" /></svg>
              </div>
            </div>
          </div>
          <div class="col-sp-12 row-sp-12 pad-8">
            <div class="bgcolor-secondary pad-16 br-10 grid-12 traitBox" v-if="userDetails.treat1 == 2">
              <div class="col-sp-2 row-sp-12 t-left">
                <img src="assets/images/books.png" class="width-40">
              </div>
              <div class="col-sp-8 row-sp-12 pad-4">
                <p class="color-grey f-normal" style="font-size: 1em;">
                  Ich möchte meine Ausbildung bei euch Beginnen.
                </p>
              </div>
              <div class="col-sp-2 row-sp-12 t-right">
                <svg width="40px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 1a11 11 0 1 0 11 11A11 11 0 0 0 12 1Zm5.707 8.707-7 7a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 1.414-1.414L10 14.586l6.293-6.293a1 1 0 0 1 1.414 1.414Z" fill="#51a7dd" class="fill-232323" /></svg>
              </div>
            </div>
          </div>
          <div class="col-sp-12 row-sp-12 pad-8">
            <div class="bgcolor-secondary pad-16 br-10 grid-12 traitBox" v-if="userDetails.treat1 == 3">
              <div class="col-sp-2 row-sp-12 t-left">
                <img src="assets/images/hands.png" class="width-40">
              </div>
              <div class="col-sp-8 row-sp-12 pad-4">
                <p class="color-grey f-normal" style="font-size: 1em;">
                  Ich habe eine abgeschlossene Ausbildung und möchte in diesem Job arbeiten.
                </p>
              </div>
              <div class="col-sp-2 row-sp-12 t-right">
                <svg width="40px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 1a11 11 0 1 0 11 11A11 11 0 0 0 12 1Zm5.707 8.707-7 7a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 1.414-1.414L10 14.586l6.293-6.293a1 1 0 0 1 1.414 1.414Z" fill="#51a7dd" class="fill-232323" /></svg>
              </div>
            </div>
          </div>
          <div class="col-sp-12 row-sp-12 pad-8">
            <div class="bgcolor-secondary pad-16 br-10 grid-12 traitBox" v-if="userDetails.treat1 == 4">
              <div class="col-sp-2 row-sp-12 t-left">
                <img src="assets/images/teacher.png" class="width-40">
              </div>
              <div class="col-sp-8 row-sp-12 pad-4">
                <p class="color-grey f-normal" style="font-size: 1em;">
                  Ich möchte ein Praktikum oder ein Freiwilliges-Soziales Jahr bei euch absolvieren.
                </p>
              </div>
              <div class="col-sp-2 row-sp-12 t-right">
                <svg width="40px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 1a11 11 0 1 0 11 11A11 11 0 0 0 12 1Zm5.707 8.707-7 7a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 1.414-1.414L10 14.586l6.293-6.293a1 1 0 0 1 1.414 1.414Z" fill="#51a7dd" class="fill-232323" /></svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sp-12 col-sd-4 row-sp-12">
        <h4 class="color-primary f-normal pad-8">
          Gewünschter Arbeitsort:
        </h4>
        <div class="select-wrapper grid-12">
          <div v-for="location in locations" :key="location.id" class="col-sp-12 row-sp-12 pad-8">
            <div class="bgcolor-secondary pad-16 br-10 grid-12 locationBox">
              <div class="col-sp-12 row-sp-12">
                <h5 class="color-primary" style="font-size: 1.2em;">
                  {{ location.name }}
                </h5>
                <h6 class="color-grey f-normal" style="font-size: 1em;">
                  {{ location.city }}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pad-8" />
  </main>
</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {nl2br} from "@/modules/main";


export default {
  name: "details",
  title: "Bewerbungen Details",
  components: {

  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      userDetails: '',
      locations: '',
      historyitems: '',
      emailSection: false,
      template: 0,
      emailContent: '',
      feedback: '',
      footer: '<br><br><p style="font-size: 14px;">Mit freundlichen Grüßen,<br><i style="font-size: 14px;">'+this.user.firstname+' '+this.user.lastname+'</i><br><br><img src="https://jobportal.20north.de/assets/images/pflegediakonie-logo-vertical.png" width="150px"/>' +
          '<br><br>Pflegediakonie Hamburg-West/Südholstein gGmbH' +
          '<br><a style="font-size: 14px; color: #51A7DD; text-decoration: none;" href="https://www.google.com/maps/search/Klopstockplatz+2+%0D%0A22765+Hamburg?entry=gmail&source=g" target="_blank">Klopstockplatz 2</a>' +
          '<br><a style="font-size: 14px; color: #51A7DD; text-decoration: none;" href="https://www.google.com/maps/search/Klopstockplatz+2+%0D%0A22765+Hamburg?entry=gmail&source=g" target="_blank">22765 Hamburg</a>' +
          '<br><br>Tel.: 040 – 39825 – 100'+
          '<br>Fax: 040 – 39825 – 199'+
          '<br>E-Mail: <a style="font-size: 14px; color: #51A7DD; text-decoration: none;" href="mailto:'+this.user.email+'" target="_blank">'+this.user.email+'</a>'+
          '<br><br>Homepage: <a style="font-size: 14px; color: #51A7DD; text-decoration: none;" href="https://www.pflegediakonie.de/" target="_blank">www.pflegediakonie.de</a> / <a style="font-size: 14px; color: #51A7DD; text-decoration: none;" href="https://jobs.pflegediakonie.de/" target="_blank">jobs.pflegediakonie.de</a>'+
          '<br>Gutes tun: <a style="font-size: 14px; color: #51A7DD; text-decoration: none;" href="https://www.pflegediakonie.de/footer/spenden?pflegediakonie-hamburg-west-suedholstein-ggmbi/spende" target="_blank">Mit einem Klick spenden</a>'+
          '<br><br><a style="font-size: 14px; color: #51A7DD; text-decoration: none;" href="https://www.facebook.com/Pflegediakonie/" target="_blank">Besuchen Sie uns bei Facebook!</a>'+
          '<br><br><i style="font-size: 12px;">Diese E-Mail kann vertrauliche und/oder rechtlich geschützte Informationen enthalten. Wenn Sie nicht der richtige Adressat sind oder diese E-Mail irrtümlich erhalten haben, informieren Sie bitte sofort den Absender und vernichten Sie diese E-Mail. Das unerlaubte Kopieren sowie die unbefugte Weiter- oder Kenntnisgabe dieser E-Mail ist nicht gestattet.' +
          'This e-mail may contain confidential and/or privileged information. If you are not the intended recipient (or have received this e-mail in error) please notify the sender immediately and destroy this e-mail. Any unauthorised copying, disclosure or distribution of the material in this e-mail is strictly forbidden.</i>' +

          '</p>',


    }
  },
  mounted() {
    this.config.loading = false
    this.getApplicationsById()
    this.getHistoryById()

  },
  methods: {
    sendMail(){
      const _this = this
      const fD = new FormData()
      fD.append('id', this.$route.params.id)
      fD.append('email', this.userDetails.email)
      fD.append('body', nl2br(this.emailContent) + this.footer)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('bewerbungen/sendMail', fD)
          .then(r => {
            if(r.status === 250) {
              this.emailSection = false
              this.feedback = 'E-Mail erfolgreich gesendet.'
              this.updateStatus(1)
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    updateStatus(status) {
      const _this = this
      const fD = new FormData()
      fD.append('id', this.$route.params.id)
      fD.append('status', status)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('bewerbungen/updateStatus', fD)
          .then((response) => {
            if(response.status === 250){
              _this.userDetails.status = status
            }
          })
    },
    getApplicationsById() {
      const _this = this
      const fD = new FormData()
      fD.append('id', this.$route.params.id)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('bewerbungen/getApplicationsById', fD)
          .then((response) => {
            if(response.status === 250){
              _this.userDetails = response.data
              this.getLocations(response.data.selectedLocations)
            }
          })
    },
    getHistoryById() {
      const _this = this
      const fD = new FormData()
      fD.append('id', this.$route.params.id)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('bewerbungen/getHistoryById', fD)
          .then((response) => {
            if(response.status === 250){
              _this.historyitems = response.data
            }
          })
    },
    changeTemplate(){
      if(this.template == 0){this.emailContent = '';}
      if(this.template == 1){this.emailContent = '<b>Sehr geehrte/r '+this.userDetails.name+',</b>\n\nvielen Dank für Ihre Bewerbung und Ihr Interesse an der ausgeschriebenen Stelle bei uns im Unternehmen. Wir freuen uns, Sie zu einem Bewerbungsgespräch einladen zu dürfen.\n' +
          '\n' +
          'Das Bewerbungsgespräch wird am [Datum] um [Uhrzeit] in unseren Räumlichkeiten stattfinden. Das genaue Adressdatum finden Sie in der Anhang zu dieser E-Mail. Bitte teilen Sie uns bis zum [Datum] mit, ob der genannte Termin für Sie passt oder ob Sie einen anderen Vorschlag haben.\n' +
          '\n' +
          'Im Rahmen des Bewerbungsgesprächs möchten wir uns gerne persönlich von Ihren Fähigkeiten und Ihrer Motivation überzeugen. Wir möchten Ihnen auch die Gelegenheit geben, unser Unternehmen und die Arbeitsbedingungen kennenzulernen sowie Fragen zu stellen.\n' +
          '\n' +
          'Um sich optimal auf das Gespräch vorzubereiten, bitten wir Sie, uns im Vorfeld Ihre vollständigen Bewerbungsunterlagen zuzusenden. Gerne können Sie uns auch bereits mitteilen, welche Punkte für Sie im Gespräch von besonderem Interesse sind.\n' +
          '\n' +
          'Wir freuen uns auf das persönliche Gespräch mit Ihnen und wünschen Ihnen eine angenehme Anreise.';}
    },
    getLocations(selectedLocations){
      const _this = this
      const fD = new FormData()
      fD.append('selectedLocations', selectedLocations)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('bewerbungen/getLocations', fD)
          .then(r => {
            if(r.status === 250) {
              _this.locations = r.data
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    nl2br(str){
      return nl2br(str)
    }
  }
}
</script>

<style scoped>
#Status .label{
  display: block;
  text-align: center;
  margin-bottom: 8px;
  opacity: 0.5;
  cursor: pointer;
}
#Status .label.active{
  opacity: 1;
  font-weight: bold;
}
#TemplateSelection{
  box-sizing: border-box;
  width: 100%;
  padding: 8px;
  border-radius: 10px;
  border: none;
}
#emailContent{
  box-sizing: border-box;
  background-color: #ffffff;
  border: none;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  padding: 16px;
  border-radius: 10px;
}
label{
  margin-bottom: 4px;
  display: block;
}
</style>