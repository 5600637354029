<template>
  <main>
    <loader v-if="loading" />
    <section class="login" v-else>
      <div class="t-center">
        <img
          src="assets/images/pflegediakonie-logo.svg"
          style="width: 100%;
          max-width: 250px;"
        >
      </div>
      <div class="t-center">
        <div class="field simple pad-8">
          <input type="text" name="firstname" placeholder="Vorname" required v-model="firstname">
        </div>
        <div class="field simple pad-8">
          <input type="text" name="lastname" placeholder="Nachname" required v-model="lastname">
        </div>
        <div class="field simple pad-8">
          <input type="tel" name="phone" placeholder="Telefonnummer" required v-model="phone">
        </div>
        <div class="field simple pad-8">
          <label>Geburtstag</label>
          <input type="date" name="dob" placeholder="Geburtstag" required v-model="dob">
        </div>
        <div class="field simple pad-8">
          <input type="password" name="password" placeholder="Passwort" required v-model="pw1">
        </div>
        <div class="field simple pad-8">
          <input type="password" name="password2" placeholder="Passwort wiederholen" required v-model="pw2">
        </div>
        <p class="feedbackmessage t-center pad-8 color-blue" v-if="!passwordCheck && pw2.length > 0">
          Das Passwort ist nicht identisch oder kürzer als 8 Zeichen.
        </p>
      </div>
      <span v-if="error">{{}}</span>
      <div class="t-center pad-16">
        <button @click="register" class="button color-white bgcolor-blue circular" v-if="passwordCheck">
          Los gehts!
        </button>
      </div>
    </section>
  </main>
</template>

<script>
import Loader from "@/components/basic/loader";
import {aSyncData} from "@/modules/main";
import {authStore} from "@/store/authStore";
export default {
  name: "RegisterView",
  components: {Loader},
  title: "Willkommen",
  props: {
    token: {
      type: String,
      required: true
    }
  },
  setup(){
    const auth = authStore()

    return {auth}
  },
  data(){
    return{
      loading: false,
      firstname: '',
      lastname: '',
      pw1: '',
      pw2: '',
      phone: '',
      dob: '',
      error: ''
    }
  },
  computed:{
    passwordCheck(){
      return this.pw1.length > 7 && this.pw1 === this.pw2
    }
  },
  methods: {
    register(){
      this.loading = true
      this.auth.register(this.firstname, this.lastname, this.pw1, this.token, this.phone, this.dob)
          .then(r => {
            this.$router.push('/dashboard')
          })
          .catch(e => {
            this.error = e
          })
          .finally(() =>  this.loading = false)
    }
  }
}
</script>

<style scoped>

</style>