<template>
  <main class="content-1400 userManagement page-content">
    <div class="grid-12">
      <div class="col-sp-12 col-tb-8 row-sp-12 pad-8">
        <h1>Nutzerverwaltung</h1>
      </div>
      <div class="col-sp-12 col-sd-4 row-sp-12 pad-8 t-right pad-top-16">
        <div class="field simple">
          <input id="search" placeholder="Suche..." name="search" type="search" v-model="searchString">
        </div>
      </div>
    </div>
    <div class="grid-12 pad-8">
      <div class="col-sp-12 row-sp-12 " v-for="nutzerli in nutzerlisFiltered" :key="nutzerli.id">
        <div class="grid-12 pad-4 bgcolor-ligthgrey br-10 mar-top-8">
          <div class="col-sp-12 col-tb-1 row-sp-12 pad-4">
            <p>Nutzer ID</p>
            <div>
              #{{ nutzerli.id }}
            </div>
          </div>
          <div class="col-sp-12 col-tb-4 row-sp-12 pad-4">
            <p>Vor- und Nachname</p>
            <div>
              {{ nutzerli.firstname }} {{ nutzerli.lastname }}
            </div>
          </div>
          <div class="col-sp-12 col-tb-3 row-sp-12 pad-4">
            <p>Nutzer ID</p>
            <div>
              {{ nutzerli.email }}
            </div>
          </div>
          <div class="col-sp-12 col-tb-3 row-sp-12 pad-4">
            <p>Telefon</p>
            <div>
              {{ nutzerli.phone }}
            </div>
          </div>
          <div class="col-sp-12 col-tb-1 row-sp-12 pad-4 t-right" @click="deleteId = nutzerli.id; deleteModal = true">
            <button class="button tiny" style="color: white; background-color: var(--theme-pflegediakonie-primary)">
              <svg style="width: 20px; color: white;" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><g data-name="1"><path d="M356.65 450H171.47a41 41 0 0 1-40.9-40.9V120.66a15 15 0 0 1 15-15h237a15 15 0 0 1 15 15V409.1a41 41 0 0 1-40.92 40.9ZM160.57 135.66V409.1a10.91 10.91 0 0 0 10.9 10.9h185.18a10.91 10.91 0 0 0 10.91-10.9V135.66Z" fill="#ffffff" class="fill-000000" /><path d="M327.06 135.66h-126a15 15 0 0 1-15-15V93.4a44.79 44.79 0 0 1 44.74-44.73h66.52a44.79 44.79 0 0 1 44.74 44.73v27.26a15 15 0 0 1-15 15Zm-111-30h96V93.4a14.75 14.75 0 0 0-14.74-14.73H230.8a14.75 14.75 0 0 0-14.73 14.73ZM264.06 392.58a15 15 0 0 1-15-15V178.09a15 15 0 1 1 30 0v199.49a15 15 0 0 1-15 15ZM209.9 392.58a15 15 0 0 1-15-15V178.09a15 15 0 0 1 30 0v199.49a15 15 0 0 1-15 15ZM318.23 392.58a15 15 0 0 1-15-15V178.09a15 15 0 0 1 30 0v199.49a15 15 0 0 1-15 15Z" fill="#ffffff" class="fill-000000" /><path d="M405.81 135.66H122.32a15 15 0 0 1 0-30h283.49a15 15 0 0 1 0 30Z" fill="#ffffff" class="fill-000000" /></g></svg>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="pad-8" />
    <div class="grid-12 pad-8">
      <div class="col-sp-12 col-sd-6 row-sp-12">
        <h2>Eingeladene</h2>
      </div>
      <div class="col-sp-12 col-sd-6 row-sp-12 t-right">
        <button @click="einladungsModel = true" class="button primary">
          Nutzer Einladen
        </button>
      </div>
    </div>
    <div class="pad-8" />
    <div class="grid-12 pad-8">
      <div class="col-sp-12 row-sp-12">
        <div v-for="invitili in invitilisFiltered" :key="invitili.id">
          <div class="grid-12 pad-4 bgcolor-ligthgrey br-10 mar-top-8">
            <div class="col-sp-12 col-tb-4 row-sp-12 pad-4">
              <p>Vor- und Nachname</p>
              <div>
                {{ invitili.name }}
              </div>
            </div>
            <div class="col-sp-12 col-tb-4 row-sp-12 pad-4">
              <p>E-Mail-Adresse</p>
              <div>
                {{ invitili.email }}
              </div>
            </div>
            <div class="col-sp-12 col-tb-3 row-sp-12 pad-4">
              <p>Eingeladen am</p>
              <div>
                {{ formatDate(invitili.created) }}
              </div>
            </div>
            <div class="col-sp-12 col-tb-1 row-sp-12 pad-4" style="align-self: center; text-align: right">
              <div>
                <button @click="revertId = invitili.id; revertModal = true" class="button primary">
                  Zurückziehen
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid-12 pad-8">
      <div class="col-sp-12 row-sp-12">
        <div v-if="!invitilisFiltered.length">
          Keine Einladungen verfügbar oder Filter nicht zutreffend
        </div>
      </div>
    </div>

    <Vue-Modal :show="einladungsModel" approve="Einladen" @approve="invite" @close="einladungsModel = false" @cancel="einladungsModel = false">
      <div v-if="!loading">
        <p
          style="font-weight: bold;
          font-size: 1.3em;
          text-align: center;
          padding-bottom: 12px;"
        >
          Nutzer einladen
        </p>
        <label>Name:</label><br>
        <input class="inputModal" type="text" v-model="name" placeholder="Name"><br>
        <label>Email:</label><br>
        <input class="inputModal" type="email" v-model="email" placeholder="Email">
      </div>
      <loader v-else />
    </Vue-Modal>
    <Vue-Modal :show="deleteModal" approve="Löschen" cancel="abbrechen" @approve="deleteUser" @close="deleteModal = false" @cancel="deleteModal = false">
      <div v-if="!loading">
        Möchtest du den Nutzer wirklich endgültig löschen?
      </div>
      <loader v-else />
    </Vue-Modal>
    <Vue-Modal :show="revertModal" approve="Zurückziehen" cancel="Abbrechen" @approve="revert" @close="revertModal = false" @cancel="revertModal = false">
      <div v-if="!loading">
        Möchtest du die Einladung zurückziehen?
      </div>
      <loader v-else />
    </Vue-Modal>
  </main>
</template>

<script>
import {aSyncData, dateToString, stringToDate} from "@/modules/main";
import Loader from "@/components/basic/loader";

export default {
  name: "NutzerVerwaltungView",
  components: {Loader},
  title: "Nutzerverwaltung",
  data(){
    return {
      searchString: '',
      nutzerlis: [],
      invitilis: [],
      einladungsModel: false,
      email: '',
      name: '',
      loading: false,
      deleteModal: false,
      revertModal: false,
      deleteId: null,
      revertId: null
    }
  },
  computed:  {
    nutzerlisFiltered(){
      return this.nutzerlis.filter(a => {
        let result = false
        for (const [key, value] of Object.entries(a)) {
          try {
            if(value.toString().toLowerCase().includes(this.searchString.toString().toLowerCase())) result = true
          }catch (e) {}
        }
        return result
      })
    },
    invitilisFiltered(){
      return this.invitilis.filter(a => {
        let result = false
        for (const [key, value] of Object.entries(a)) {
          try {
            if(value.toString().toLowerCase().includes(this.searchString.toString().toLowerCase())) result = true
          }catch (e) {}
        }
        return result
      })
    }
  },
  mounted() {
    this.loadNutzerlis()
  },
  methods: {
    loadNutzerlis(){
      aSyncData('nutzerverwaltung/getAllNutzerlis')
          .then(r => {
            if(r.status === 250) {
              this.nutzerlis = r.data.nutzerlis
              this.invitilis = r.data.invitilis
            }
          })
    },
    invite(){
      this.loading = true
      aSyncData('nutzerverwaltung/invite', {email: this.email, name: this.name})
          .then(r => {
            if(r.status === 250){
              this.einladungsModel = false
              this.name = ''
              this.email = ''
              this.loadNutzerlis()
            }
          })
          .catch(e => {
            console.log(e)
          })
          .finally(() => {this.loading = false})
    },
    deleteUser(){
      this.loading = true
      aSyncData('nutzerverwaltung/deleteUser', {userId: this.deleteId})
          .then(r => {
            console.log(r)
            if(r.status === 250){
              this.nutzerlis = this.nutzerlis.filter(i => i.id !== this.deleteId)
              this.deleteId = null
              this.deleteModal = false
            }
          })
          .catch(e => {
            console.log(e)
          })
          .finally(() => {this.loading = false})
    },
    revert(){
      this.loading = true
      aSyncData('nutzerverwaltung/revert', {inviteId: this.revertId})
          .then(r => {
            console.log(r)
            if(r.status === 250){
              this.invitilis = this.invitilis.filter(i => i.id !== this.revertId)
              this.revertId = null
              this.revertModal = false
            }
          })
          .catch(e => {
            console.log(e)
          })
          .finally(() => {this.loading = false})
    },
    formatDate(string){
      return dateToString(stringToDate(string))
    }
  }
}
</script>

<style scoped>

.userManagement p{
  color: var(--theme-pflegediakonie-primary);
  font-weight: bold;
  padding-bottom: 4px;
}

.inputModal{
  margin-top: 4px;
  display: block;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--theme-pflegediakonie-secondary);
  border-radius: 4px;
  border: none;
  padding: 12px;
  max-width: 100%;
}
</style>