import { createRouter, createWebHistory } from 'vue-router'

import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";

import login from "@/views/loginView";

import navBar from "@/components/navBar/navBar";
import dashboardView from "@/views/dashboardView";
import bewerbungen from "@/views/bewerbungen";
import bewerbungenOverview from "@/views/bewerbungen/overview";
import bewerbungenDetails from "@/views/bewerbungen/details";
import jobs from "@/views/jobs";
import profil from "@/views/profil";
import jobsOverview from "@/views/jobs/overview";
import jobsCreate from "@/views/jobs/create";
import jobsEdit from "@/views/jobs/edit";
import settings from "@/views/settings";
import NutzerVerwaltungView from "@/views/NutzerVerwaltungView";
import RegisterView from "@/views/RegisterView";
import ApplyView from "@/views/ApplyView";

const routes = [
  {
    path: '/apply',
    name: 'Apply',
    components: {
      default: ApplyView
    }
  },
  {
    path: '/',
    alias: '/login',
    name: 'Login',
    components: {
      default: login
    },
    meta: { noAuth: true },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    components: {
      navBar: navBar,
      default: dashboardView
    },
    meta: { auth: true },
  },
  {
    path: '/jobs/:page?',
    name: 'jobs',
    components: {
      navBar: navBar,
      default: jobs,
    },
    children: [
      {
        path: '',
        name: 'JobsOverview',
        component: jobsOverview
      },
      {
        path: 'create',
        name: 'JobsCreate',
        component: jobsCreate
      },
      {
        path: 'edit/:id',
        name: 'JobsEdit',
        component: jobsEdit
      }
    ],
    meta: { auth: true },
  },
  {
    path: '/bewerbungen/:page?',
    name: 'bewerbungen',
    components: {
      navBar: navBar,
      default: bewerbungen,
    },
    children: [
      {
        path: '',
        name: 'BewerbungenOverview',
        component: bewerbungenOverview
      },
      {
        path: 'details/:id',
        name: 'BewerbungenDetails',
        component: bewerbungenDetails
      }
    ],
    meta: { auth: true },
  },
  {
    path: '/Nutzer',
    name: 'Nutzer',
    components: {
      navBar: navBar,
      default: NutzerVerwaltungView,
    },
    meta: { auth: true },
  },
  {
    path: '/register/:token',
    name: 'Register',
    components: {
      default: RegisterView,
    },
    props: true,
    meta: { noAuth: true },
  },
  {
    path: '/jobs',
    name: 'Jobs',
    components: {
      navBar: navBar,
      default: jobs
    },
    meta: { auth: true },
  },
  {
    path: '/profil',
    name: 'Profil',
    components: {
      navBar: navBar,
      default: profil
    },
    meta: { auth: true },
  },
  {
    path: '/settings',
    name: 'Settings',
    components: {
      navBar: navBar,
      default: settings
    },
    meta: { auth: true },
  },
  {
    name: '404',
    path: '/:pathMatch(.*)*',
    alias: '/404',
    redirect: () => {
      return { path: '/' }
    },
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const auth = authStore()
  const config = configStore()

  window.scrollTo(0, 0)
  window.onscroll = function () {}

  if (to.matched.some(record => record.meta.auth)) {
    auth.update()
        .then(r => {
          config.loading = true
          next()
        })
        .catch(e => {
          console.error(e)
          next({
            path: '/login',
            query: { redirect: encodeURI(to.fullPath) }
          })
        })
  } else if (to.matched.some(record => record.meta.noAuth)) {
    auth.update()
        .then(() => {
          next({
            name: 'Dashboard'
          })
        })
        .catch((e)=> {
          next()
        })
        .finally(function () {
          config.loading = true
        })
  } else {
    auth.update()
        .catch(e =>{
        })
        .finally(() => {
          config.loading = true
          next()
        })
  }
})

let history = []
router.afterEach((to, from) => {
  if(to.path === history.at(-2)?.path){
    to.meta.transition = history.at(-2).direction === 'slide-right' ? 'slide-left' : 'slide-right'
    history.pop()
  }else{
    to.meta.transition = to.path.split('/').length < from.path.split('/').length ? 'slide-right' : 'slide-left'
    history.push({path: to.path, direction: to.meta.transition})
  }
})

export default router
