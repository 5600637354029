<template>
  <div>
    <header class="t-center pad-24">
      <!--      <img src="assets/images/pflegediakonie-logo-round.png" alt="Pflegediakonie Logo" class="width-150">-->
    </header>
    <main class="content-1000">
      <section class="funnel funnel-start" v-if="step == 0">
        <div class="t-center">
          <h3 class="mar-bottom-8 color-primary">
            Jetzt in nur einer Minute bewerben!
          </h3>
          <p class="color-grey">
            In nur einer Minute kannst du dich bewerben und deinem Traumberuf einen Schritt näherkommen.
          </p>
        </div>
        <div class="question-general-experience">
          <h3 class="t-center mar-top-32 mar-bottom-16" style="font-size: 1.3em;">
            Hast du bereits Erfahrung in deiner Wunschposition?
          </h3>
          <div class="question-general-experience-wrapper grid-12">
            <div class="col-sp-6 row-sp-12 pad-8">
              <div class="bgcolor-secondary t-center pad-32 br-10 box" @click="step = 1; experience = 1;">
                <img src="assets/images/thumbs-up_1f44d.png" alt="Daumen nach oben Emoji">
                <h6 class="color-primary">
                  Ja habe ich
                </h6>
              </div>
            </div>
            <div class="col-sp-6 row-sp-12 pad-8">
              <div class="bgcolor-secondary t-center pad-32 br-10 box" @click="step = 1; experience = 0;">
                <img src="assets/images/thumbs-down_1f44e.png" alt="Daumen nach unten Emoji">
                <h6 class="color-primary">
                  Noch nicht
                </h6>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!--
      <section class="funnel funnel-location" v-if="step == 1">
        <div class="color-grey t-center">
          Frage 2 von 4
        </div>
        <div class="t-center mar-bottom-32">
          <h3 class="color-primary">Welcher Standort sagt Dir zu?</h3>
          <p class="color-grey">(Mehrfachauswahl möglich)</p>
        </div>
        <div class="select-wrapper grid-12">
          <div v-for="location in locations" :key="location.id" class="col-sp-12 col-sd-6 row-sp-12 pad-8">
            <div class="bgcolor-secondary pad-16 br-10 grid-12 locationBox" @click="addToSelected(location.id); toggleActive(location);" :class="{ active: location.isActive }">
              <div class="col-sp-10 row-sp-12">
                <h5 class="color-primary" style="font-size: 1.2em;">{{location.name}}</h5>
                <h6 class="color-grey f-normal" style="font-size: 1em;">{{location.city}}</h6>
              </div>
              <div class="col-sp-2 row-sp-12 t-right">
                <svg width="40px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 1a11 11 0 1 0 11 11A11 11 0 0 0 12 1Zm5.707 8.707-7 7a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 1.414-1.414L10 14.586l6.293-6.293a1 1 0 0 1 1.414 1.414Z" fill="#51a7dd" class="fill-232323"></path></svg>
              </div>
            </div>
          </div>
        </div>
        <div class="pad-8">
          <button class="fluid button large primary" @click="step = 2;">Weiter</button>
        </div>

      </section>
      -->

      <section class="funnel funnel-education" v-if="step == 1">
        <div class="color-grey t-center">
          Frage 2 von 3
        </div>
        <div class="t-center mar-bottom-32">
          <h3 class="color-primary">
            Welche Eigenschaft bringst du mit?
          </h3>
          <p class="color-grey">
            (Mehrfachauswahl möglich)
          </p>
        </div>
        <div class="select-wrapper grid-12">
          <div class="col-sp-12 col-sd-6 row-sp-12 pad-8">
            <div class="bgcolor-secondary pad-16 br-10 grid-12 traitBox" :class="{ active: treat1 }" @click="treat1 = !treat1">
              <div class="col-sp-2 row-sp-12 t-left">
                <img src="assets/images/bild-28.png" class="width-40">
              </div>
              <div class="col-sp-8 row-sp-12 pad-4">
                <p class="color-grey f-normal" style="font-size: 1em;">
                  Ich habe noch nie in diesem Beruf gearbeitet, möchte es jetzt aber.
                </p>
              </div>
              <div class="col-sp-2 row-sp-12 t-right">
                <svg width="40px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 1a11 11 0 1 0 11 11A11 11 0 0 0 12 1Zm5.707 8.707-7 7a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 1.414-1.414L10 14.586l6.293-6.293a1 1 0 0 1 1.414 1.414Z" fill="#51a7dd" class="fill-232323" /></svg>
              </div>
            </div>
          </div>
          <div class="col-sp-12 col-sd-6 row-sp-12 pad-8">
            <div class="bgcolor-secondary pad-16 br-10 grid-12 traitBox" :class="{ active: treat2 }" @click="treat2 = !treat2">
              <div class="col-sp-2 row-sp-12 t-left">
                <img src="assets/images/books.png" class="width-40">
              </div>
              <div class="col-sp-8 row-sp-12 pad-4">
                <p class="color-grey f-normal" style="font-size: 1em;">
                  Ich möchte meine Ausbildung bei euch Beginnen.
                </p>
              </div>
              <div class="col-sp-2 row-sp-12 t-right">
                <svg width="40px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 1a11 11 0 1 0 11 11A11 11 0 0 0 12 1Zm5.707 8.707-7 7a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 1.414-1.414L10 14.586l6.293-6.293a1 1 0 0 1 1.414 1.414Z" fill="#51a7dd" class="fill-232323" /></svg>
              </div>
            </div>
          </div>
          <div class="col-sp-12 col-sd-6 row-sp-12 pad-8">
            <div class="bgcolor-secondary pad-16 br-10 grid-12 traitBox" :class="{ active: treat3 }" @click="treat3 = !treat3">
              <div class="col-sp-2 row-sp-12 t-left">
                <img src="assets/images/hands.png" class="width-40">
              </div>
              <div class="col-sp-8 row-sp-12 pad-4">
                <p class="color-grey f-normal" style="font-size: 1em;">
                  Ich habe eine abgeschlossene Ausbildung und möchte in diesem Job arbeiten.
                </p>
              </div>
              <div class="col-sp-2 row-sp-12 t-right">
                <svg width="40px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 1a11 11 0 1 0 11 11A11 11 0 0 0 12 1Zm5.707 8.707-7 7a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 1.414-1.414L10 14.586l6.293-6.293a1 1 0 0 1 1.414 1.414Z" fill="#51a7dd" class="fill-232323" /></svg>
              </div>
            </div>
          </div>
          <div class="col-sp-12 col-sd-6 row-sp-12 pad-8">
            <div class="bgcolor-secondary pad-16 br-10 grid-12 traitBox" :class="{ active: treat4 }" @click="treat4 = !treat4">
              <div class="col-sp-2 row-sp-12 t-left">
                <img src="assets/images/teacher.png" class="width-40">
              </div>
              <div class="col-sp-8 row-sp-12 pad-4">
                <p class="color-grey f-normal" style="font-size: 1em;">
                  Ich möchte ein Praktikum oder ein Freiwilliges-Soziales Jahr bei euch absolvieren.
                </p>
              </div>
              <div class="col-sp-2 row-sp-12 t-right">
                <svg width="40px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 1a11 11 0 1 0 11 11A11 11 0 0 0 12 1Zm5.707 8.707-7 7a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 1.414-1.414L10 14.586l6.293-6.293a1 1 0 0 1 1.414 1.414Z" fill="#51a7dd" class="fill-232323" /></svg>
              </div>
            </div>
          </div>
        </div>
        <div class="pad-8">
          <button class="fluid button large primary" @click="step = 2;">
            Weiter
          </button>
        </div>
      </section>

      <section class="funnel funnel-education" v-if="step == 2">
        <div class="color-grey t-center">
          Letzte Frage
        </div>
        <div class="t-center mar-bottom-32">
          <h3 class="color-primary">
            Wann können wir dich erreichen?
          </h3>
          <p class="color-grey">
            (Mehrfachauswahl möglich)
          </p>
        </div>
        <div class="select-wrapper grid-12">
          <div class="col-sp-12 col-sd-6 row-sp-12 pad-8">
            <div class="bgcolor-secondary pad-16 br-10 grid-12 traitBox" :class="{ active: time1 }" @click="time1 = !time1">
              <div class="col-sp-2 row-sp-12 t-left">
                <img src="assets/images/sun.png" class="width-40">
              </div>
              <div class="col-sp-8 row-sp-12 pad-4">
                <h5 class="color-grey f-normal mar-top-8" style="font-size: 1.2em;">
                  Jederzeit
                </h5>
              </div>
              <div class="col-sp-2 row-sp-12 t-right">
                <svg width="40px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 1a11 11 0 1 0 11 11A11 11 0 0 0 12 1Zm5.707 8.707-7 7a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 1.414-1.414L10 14.586l6.293-6.293a1 1 0 0 1 1.414 1.414Z" fill="#51a7dd" class="fill-232323" /></svg>
              </div>
            </div>
          </div>
          <div class="col-sp-12 col-sd-6 row-sp-12 pad-8">
            <div class="bgcolor-secondary pad-16 br-10 grid-12 traitBox" :class="{ active: time2 }" @click="time2 = !time2">
              <div class="col-sp-2 row-sp-12 t-left">
                <img src="assets/images/clock.png" class="width-40">
              </div>
              <div class="col-sp-8 row-sp-12 pad-4">
                <h5 class="color-grey f-normal mar-top-8" style="font-size: 1.2em;">
                  Vormittags von 8 - 12 Uhr
                </h5>
              </div>
              <div class="col-sp-2 row-sp-12 t-right">
                <svg width="40px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 1a11 11 0 1 0 11 11A11 11 0 0 0 12 1Zm5.707 8.707-7 7a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 1.414-1.414L10 14.586l6.293-6.293a1 1 0 0 1 1.414 1.414Z" fill="#51a7dd" class="fill-232323" /></svg>
              </div>
            </div>
          </div>
          <div class="col-sp-12 col-sd-6 row-sp-12 pad-8">
            <div class="bgcolor-secondary pad-16 br-10 grid-12 traitBox" :class="{ active: time3 }" @click="time3 = !time3">
              <div class="col-sp-2 row-sp-12 t-left">
                <img src="assets/images/clock-2.png" class="width-40">
              </div>
              <div class="col-sp-8 row-sp-12 pad-4">
                <h5 class="color-grey f-normal mar-top-8" style="font-size: 1.2em;">
                  Nachmittags von 12 - 16 Uhr.
                </h5>
              </div>
              <div class="col-sp-2 row-sp-12 t-right">
                <svg width="40px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 1a11 11 0 1 0 11 11A11 11 0 0 0 12 1Zm5.707 8.707-7 7a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 1.414-1.414L10 14.586l6.293-6.293a1 1 0 0 1 1.414 1.414Z" fill="#51a7dd" class="fill-232323" /></svg>
              </div>
            </div>
          </div>
        </div>
        <div class="pad-8">
          <button class="fluid button large primary" @click="step = 3;">
            Weiter
          </button>
        </div>
      </section>

      <section class="funnel funnel-submit" v-if="step == 3">
        <div class="color-grey t-center">
          Super, du passt zu uns!
        </div>
        <div class="t-center mar-bottom-32">
          <h4 class="color-primary">
            Damit wir uns bei dir melden, benötigen wir noch deine Kontaktdaten
          </h4>
        </div>
        <form @submit.prevent="submitForm()">
          <div class="select-wrapper grid-12">
            <div class="col-sp-12 col-sd-6 row-sp-12 pad-8">
              <div class="bgcolor-secondary pad-16 br-10 grid-12 traitBox">
                <div class="col-sp-2 row-sp-12 t-left">
                  <img src="assets/images/bust-in-silhouette_1f464.png" class="width-40">
                </div>
                <div class="col-sp-10 row-sp-12 pad-4">
                  <input type="text" v-model="name" placeholder="Dein Name" required>
                </div>
              </div>
            </div>
            <div class="col-sp-12 col-sd-6 row-sp-12 pad-8">
              <div class="bgcolor-secondary pad-16 br-10 grid-12 traitBox">
                <div class="col-sp-2 row-sp-12 t-left">
                  <img src="assets/images/envelope_2709-fe0f.png" class="width-40">
                </div>
                <div class="col-sp-10 row-sp-12 pad-4">
                  <input type="email" v-model="email" placeholder="Deine E-Mail-Adresse" required>
                </div>
              </div>
            </div>
            <div class="col-sp-12 col-sd-6 row-sp-12 pad-8">
              <div class="bgcolor-secondary pad-16 br-10 grid-12 traitBox">
                <div class="col-sp-2 row-sp-12 t-left">
                  <img src="assets/images/telephone-receiver_1f4de.png" class="width-40">
                </div>
                <div class="col-sp-10 row-sp-12 pad-4">
                  <input type="tel" v-model="phone" placeholder="Deine Telefonnummer" required>
                </div>
              </div>
            </div>
            <div class="col-sp-12 row-sp-12 pad-8 t-center">
              <input id="privacy" type="checkbox" name="privacy" v-model="agree" required>
              <label for="privacy"> Ich habe die <a class="color-blue" target="_blank" href="https://www.pflegediakonie.de/footer/datenschutz">Datenschutzbestimmungen</a> gelesen und aktzeptiere sie.</label>
            </div>
          </div>
          <div class="t-center">
            <div class="label red" v-if="formSubmitted && !formValid || formSubmitted && !validEmail || formSubmitted && !validPhone">
              <p v-if="formSubmitted && !formValid">
                Bitte füllen Sie alle Felder aus.
              </p>
              <p v-if="formSubmitted && !validEmail">
                Bitte geben Sie eine gültige E-Mail-Adresse ein.
              </p>
              <p v-if="formSubmitted && !validPhone">
                Bitte geben Sie eine gültige Telefonnummer ein.
              </p>
            </div>
          </div>
          <div class="pad-8">
            <button class="fluid button large primary" type="submit">
              Absenden
            </button>
          </div>
        </form>
      </section>

      <section class="funnel funnel-submit" v-if="step == 4">
        <div class="t-center">
          <img src="assets/images/finish.gif" class="responsive-img width-100">
        </div>
        <div class="pad-16" />
        <div class="t-center mar-bottom-32">
          <h3 class="color-primary">
            Danke für deine Bewerbung
          </h3>
          <p class="color-grey">
            Wir melden uns schnellstmöglich bei dir!
          </p>
        </div>
        <div class="pad-8 t-center">
          <a href="https://jobs.pflegediakonie.de/" class="button primary" @click="step = 5;">Zurück zur Pflegediakonie</a>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {aSyncData} from "@/modules/main";

export default {
  name: "funnel",
  title: "Funnel",
  props: {
    jobId: {
      type: Number,
      required: true
    }
  },
  setup() {
    const auth = authStore()
    const config = configStore()

    return {auth, config}
  },
  data(){
    return {
      step: 0,
      locations: '',
      experience: '',
      active_el: 0,
      treat1: false,
      treat2: false,
      treat3: false,
      treat4: false,
      time1: false,
      time2: false,
      time3: false,
      name: '',
      email: '',
      phone: '',
      agree: false,
      formSubmitted: false,
      formValid: true,
      validEmail: true,
      validPhone: true,
      selectedLocations: [],
    }
  },
  computed:{

  },
  mounted() {
    this.config.loading = false
    this.getLocations()
  },
  methods: {
    addToSelected(id) {
      const index = this.selectedLocations.indexOf(id);
      if (index === -1) {
        this.selectedLocations.push(id);
      } else {
        this.selectedLocations.splice(index, 1);
      }
    },
    toggleActive(item) {
      item.isActive = !item.isActive;
    },
    activate:function(el){
      this.active_el = el;
    },
    getLocations(){
      aSyncData('funnel/getLocations', {id: this.jobId})
          .then(r => {
            if(r.status === 250) {
              console.log(r.data)
              this.locations = r.data
            }
          })
          .catch(e => {
            console.log(e)
          })
    },
    submitForm() {
      this.validPhone = true;
      this.validEmail = true;
      this.formSubmitted = true;
      if (this.name.length >= 1) {
        const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
        if (!phoneRegex.test(this.phone)) {
          this.validPhone = false;
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(this.email)) {
          this.validEmail = false;
        }
        this.formValid = true;

        if (this.validEmail && this.validPhone) {
          const fD = {
            id: this.jobId,
            treat1: this.treat1,
            treat2: this.treat2,
            treat3: this.treat3,
            treat4: this.treat4,
            time1: this.time1,
            time2: this.time2,
            name: this.name,
            email: this.email,
            phone: this.phone,
          }
          aSyncData('funnel/sendApplication', fD)
              .then(r => {
                console.log(r)
                if(r.status === 250) {
                  this.step = 4;
                }
              })
              .catch(e => {
                console.log(e)
              })
        }
      } else {
        this.formValid = false;
      }
    },
  }
}
</script>

<style scoped>
.box{
  transform: scale(1);
  transition: 0.3s;
  cursor: pointer;
}
.box:hover{
  transform: scale(1.05);
}
.traitBox,
.locationBox{
  cursor: pointer;
  border: 2px solid transparent;
}
.traitBox svg,
.locationBox svg{
  display: none;
}
.traitBox.active svg,
.locationBox.active svg{
  display: inline;
}
.traitBox.active,
.locationBox.active{
  border: 2px solid var(--theme-pflegediakonie-primary);
}
.traitBox input{
  width: calc(100% - 16px);
  display: block;
  padding: 8px;
  border: none;
  background-color: transparent;
  border-bottom: 2px solid var(--theme-pflegediakonie-primary);
}
.traitBox input:focus{
  outline: none;
}
</style>