<template>
  <article class="col-sp-12 row-sp-12 pad-4 grid-12 bgcolor-ligthgrey br-10 mar-top-8">
    <div class="col-sp-12 col-tb-2 col-sd-2 row-sp-12 pad-4 t-left">
      <div class="pad-top-4">
        <a v-html="status" />
      </div>
    </div>
    <div class="col-sp-12 col-tb-4 col-sd-3 row-sp-12 pad-4">
      <p>
        <b class="color-primary">Bewerber*in:</b><br>
        [#{{ application.id }}] {{ application.name }}
      </p>
    </div>
    <div class="col-sp-12 col-tb-6 col-sd-3 row-sp-12 pad-4">
      <p>
        <b class="color-primary">Stelle:</b><br>
        {{ application.title }} ({{ application.worktype }})
      </p>
    </div>
    <div class="col-sp-12 col-tb-10 col-sd-3 row-sp-12 pad-4">
      <p>
        <b class="color-primary">Kontakt:</b><br>
        {{ application.phone }} | {{ application.email }}
      </p>
    </div>
    <div class="col-sp-12 col-tb-2 col-sd-1 row-sp-12 pad-4 t-right pad-top-8">
      <button class="button tiny blue" @click="$router.push({name: 'BewerbungenDetails', params: { id: application.id }});">
        <svg width="20px" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="m402.3 344.9 32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174 402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z" fill="#ffffff" class="fill-000000" /></svg>
      </button>
    </div>
  </article>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "Application",
  props: ["application"],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  computed: {
    status(){
      switch (this.application.status) {
        case 0:
          return '<span class="label blue">Neue Bewerbung</span>'

        case 1:
          return '<span class="label blue">In Korrespondenz</span>'

        case 2:
          return '<span class="label blue">Vorstellungsgespräch</span>'

        case 3:
          return '<span class="label blue">Vorstellungsgespräch</span>'

        case 4:
          return '<span class="label red">Abgelehnt</span>'

        case 5:
          return '<span class="label yellow">Auf Warteliste</span>'
      }
      return  ''
    }
  },
  mounted() {
    this.config.loading = false
  }
}
</script>

<style scoped>
h3{
  font-size: 1.4em;
}
h5{
  font-size: 1.2em;
  color: #00000070;
}
article p{
  font-size: 0.95em;
}
</style>