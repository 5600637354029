<template>
  <article class="col-sp-12 col-tb-6 col-sd-4 row-sp-12 pad-8 grid-12">
    <div class="col-sp-12 row-sp-12 bgcolor-secondary pad-16 br-10">
      <div class="grid-12">
        <div class="col-sp-4 row-sp-12">
          <p class="color-grey">
            ID: #{{ jobAd.id }}
          </p>
        </div>
        <div class="col-sp-8 row-sp-12 t-right">
          <div v-if="jobAd.deleted">
            <button type="button" class="button tiny black" @click="$emit('deleteJobAd', jobAd.id);">
              Wiederherstellen
            </button>
            <button type="button" class="button tiny primary mar-left-4" @click="$router.push({name: 'JobsEdit', params: { id: jobAd.id }});">
              Bearbeiten
            </button>
          </div>
          <div v-else>
            <button type="button" class="button tiny black" @click="$emit('deleteJobAd', jobAd.id);">
              Deaktivieren
            </button>
            <button type="button" class="button tiny primary mar-left-4" @click="$router.push({name: 'JobsEdit', params: { id: jobAd.id }});">
              Bearbeiten
            </button>
          </div>
        </div>
      </div>
      <h3 style="padding-top: 12px;">
        {{ jobAd.title }}
      </h3>
      <h5 class="f-light" style="padding-top: 6px; font-size: 16px; font-weight: 400">
        {{ jobAd.worktype }}
      </h5>
      <p class="mar-top-12">
        <span v-for="location in locations" :key="location.id" class="tiny blue label" style="margin-bottom: 4px;">
          <svg height="16px" id="Layer_1" style="opacity: 0.5; vertical-align: -3px; margin: 0 -4px; enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" width="24px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><path d="M256,64c-65.9,0-119.3,53.7-119.3,120c0,114.6,119.3,264,119.3,264s119.3-149.4,119.3-264C375.3,117.7,321.9,64,256,64z    M256,242.2c-31.2,0-56.4-25.4-56.4-56.7c0-31.3,25.3-56.8,56.4-56.8c31.2,0,56.4,25.4,56.4,56.8   C312.4,216.8,287.2,242.2,256,242.2z" /></g></svg>
          {{ location.name }} - {{ location.city }}
        </span>
      </p>
    </div>
  </article>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import {aSyncData} from "@/modules/main";

export default {
  name: "JobAd",
  props: ["jobAd"],
  emits: ['deleteJobAd'],
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      locations: []
    }
  },
  mounted() {
    this.config.loading = false
    this.getLocations()
  },
  methods: {
    getLocations(){
      aSyncData('bewerbungen/getLocations', {selectedLocations: this.jobAd.location})
          .then(r => {
            if(r.status === 250) this.locations = r.data
          })
          .catch(e => {
            console.log(e)
          })
    },
  }
}
</script>

<style scoped>
h3{
  font-size: 1.4em;
}
h5{
  font-size: 1.2em;
  color: #00000070;
}

.flex {
  display: flex;
  column-gap: 6px;
  justify-content: flex-end;
}

.trash {
  padding: 2px 4px;

}
</style>