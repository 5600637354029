<template>
  <main class="content-1400 page-content">
    <div class="grid-12">
      <div class="col-sp-12 col-tb-8 row-sp-12 pad-8">
        <h1>Einstellungen</h1>
      </div>
      <div class="col-sp-12 col-sd-4 row-sp-12 pad-8 t-right pad-top-16">
        <router-link to="jobs/create" class="button primary">
          Neuen Benutzer erstellen
        </router-link>
      </div>
    </div>
    <div class="pad-8" />
    <div class="grid-12">
      <Application v-for="Application in Applications" :key="Application.id" :application="Application" />
    </div>

    <div class="pad-8" />
  </main>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "settings",
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
}
</script>

<style scoped>

</style>