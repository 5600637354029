import { defineStore } from 'pinia'
import {aSyncData} from "@/modules/main";

export const userStore = defineStore('user', {
    state: () => ({
        id: -1,
        firstname: '',
        lastname: '',
        email: '',
        avatar: '',
    }),
    actions: {

    }
})