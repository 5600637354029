<template>
  <main class="content-1400 page-content">
    <div class="pad-16" />
    <div class="pad-8">
      <img src="assets/images/Pflegediakonie-Jobportal-Home-Auswahl-03.jpeg" class="responsive-img br-10">
    </div>
    <div class="grid-12">
      <div class="col-sp-12 col-sd-6 row-sp-12 pad-8">
        <div class="bgcolor-primary br-10 pad-24">
          <h1 class="SueEllenFrancisco color-white">
            Hey {{ user.firstname }},
          </h1>
          <p class="color-white">
            Herzlich willkommen im Job- und Bewerberverwaltungsportal! Schön, dass du Teil unseres Teams bist und dich mit Engagement und Leidenschaft um die Bewerberverwaltung und die Jobanzeigen auf unserer Plattform kümmerst.
            <br><br>
            Hier hast du die Möglichkeit, Stellenanzeigen zu erstellen und zu verwalten, Bewerbungen zu sichten und zu bearbeiten sowie mit den Bewerber*innen zu kommunizieren.
          </p>
          <div class="pad-8" />
        </div>
      </div>
      <div class="col-sp-6 col-tb-4 col-sd-2 row-sp-12 pad-8">
        <div class="bgcolor-secondary br-10 pad-24 statsBox">
          <h4 class="SueEllenFrancisco color-primary t-center">
            {{ applications.filter(a => a.status === 0).length }}
          </h4>
          <h5 class="color-primary SueEllenFrancisco t-center">
            Neue Bewerbungen
          </h5>
        </div>
      </div>
      <div class="col-sp-6 col-tb-4 col-sd-2 row-sp-12 pad-8">
        <div class="bgcolor-secondary br-10 pad-24 statsBox">
          <h4 class="SueEllenFrancisco color-primary t-center">
            {{ jobs.length }}
          </h4>
          <h5 class="color-primary SueEllenFrancisco t-center">
            Stellenanzeigen
          </h5>
        </div>
      </div>
      <div class="col-sp-12 col-tb-4 col-sd-2 row-sp-12 pad-8">
        <div class="bgcolor-secondary br-10 pad-24 statsBox">
          <h4 class="SueEllenFrancisco color-primary t-center">
            {{ applications.length }}
          </h4>
          <h5 class="color-primary SueEllenFrancisco t-center">
            Gesamte Bewerbungen
          </h5>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {aSyncData} from "@/modules/main";

export default {
  name: "dashboardView",
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      applications: [],
      jobs: []
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init(){
      aSyncData('dashboard/getInfos')
          .then(r => {
            console.log(r)
            if(r.status === 250){
              this.applications = r.data.applications
              this.jobs = r.data.jobs
            }
          })
          .catch(e => {console.log(e)})
    }
  }
}
</script>

<style scoped>
.statsBox h4{
  font-size: 2.8em;
  margin: 0;
  padding: 0;
}
</style>